interface UserData {
  id: number;
  username: string;
  email: string;
  provider: string;
  confirmed: boolean;
  blocked: boolean;
  instructor: boolean;
  createdAt: string;
  updatedAt: string;
  is_admin: boolean;
  onesignalToken: string;
  is_student: boolean | null;
  is_teacher: boolean | null;
  enrolement_id: number | null;
  online_status: boolean;
  status_setting: string;
}
export const getJwt = () => {
  const jwt = getCookie('token');
  return jwt || '';
};

export const getUser = () => {
  const user = getCookie('user');
  return user || '';
};
export const RememberUser = (user: Object) => {
  let date = new Date();
  date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
  document.cookie = `remembered=${JSON.stringify(user)}; path=/; domain=gurucool.xyz; expires=${date.toUTCString()};`;
}
export const getRememberUser = () => {
  const user = getCookie('remembered');
  return user || false;
};

export const checkLogin = () => {
  const user = getUser();
  const jwt = getJwt();
  if (user && jwt) {
    return true;
  }
  return false;
}
export const destroyRemember = () => {
  let date = new Date();
  document.cookie = `remembered=''; path=/; domain=gurucool.xyz; expires=${date.toUTCString()};`;
};

export const storeJwt = (jwt: string) => {
  let date = new Date();
  date.setTime(date.getTime() + (720 * 60 * 60 * 1000));
  document.cookie = `token=${jwt}; path=/; domain=gurucool.xyz; expires=${date.toUTCString()};`;
};

export const storeUser = (user: UserData) => {
  let date = new Date();
  date.setTime(date.getTime() + (720 * 60 * 60 * 1000));

  // Extract only the desired properties (id, username, and email)
  const { id, username, email } = user;

  // Create a new object with the extracted properties
  const strippedUserData = { id, username, email };

  // Store the strippedUserData in the cookie
  document.cookie = `user=${JSON.stringify(strippedUserData)}; path=/; domain=gurucool.xyz; expires=${date.toUTCString()};`;
};

export const destroyUser = () => {
  let date = new Date();
  document.cookie = `token=''; path=/; domain=gurucool.xyz; expires=${date.toUTCString()};`;
  document.cookie = `user=''; path=/; domain=gurucool.xyz; expires=${date.toUTCString()};`;
  document.cookie = `user_id=''; path=/; domain=gurucool.xyz; expires=${date.toUTCString()};`;
};

type Cookie = {
  [key: string]: string;
};

export const getCookie = (name: string) => {
  let cookie: Cookie = {};
  document.cookie.split(';').forEach(function (el) {
    let [k, v] = el.split('=');
    cookie[k.trim()] = v;
  });
  return cookie[name];
};


